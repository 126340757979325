import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  list: {
    paddingLeft: theme.spacing(3)
  },
  featureFalse: {
    color: theme.palette.error.main
  },
  featureNeutral: {
    color: theme.palette.warning.main
  },
  featureTrue: {
    color: theme.palette.success.main
  },
}));

const Pro  = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader title="DataMerch Pro Membership" />
          <Divider />
          <CardContent className={classes.terms}>
            <Typography
              variant="h4"
            >
              What is DataMerch Pro?
            </Typography>
            <br></br>
            <Typography
              variant="subtitle2"
            >
              <iframe width="560" height="315" src="https://www.youtube.com/embed/rPxwRSO_aK8?si=iuHIAKUGT88Wc00D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            <Typography
              variant="h4"
            >
              What is included with DataMerch Pro?
            </Typography>
            <br></br>
            <Typography
              variant="subtitle2"
            >
              <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Website Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Search for Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add New Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Add Notes to Merchants" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="API Access" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="UNLIMITED Searches Per Day" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText primary="Keyword search returns up to 25 matches" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckBoxIcon className={classes.featureTrue} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Merchant Subscriptions"
                      secondary="Be notified automatically when updates occur"
                    />
                  </ListItem>
                </List>
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            <Typography
              variant="h4"
            >
              How do I get DataMerch Pro?
            </Typography>
            <br></br>
            <Typography
              variant="h5"
            >
              Instructions for upgrading to DataMerch Pro are available <a href="/docs/Merchant_subscriptions_directions.pdf">here</a>.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Pro;
