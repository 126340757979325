import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import axios_default from '../../httpClients/axios-default'
import {authHeaders} from '../../helpers'
import {clearNotes} from './note'
import { history } from '../../Routes'
import {addToast} from './toasts'
import { ContactSupportOutlined } from '@material-ui/icons'

export const clearMerchants = () => {
  return {
    type: actionTypes.FETCH_MERCHANTS_SUCCESS,
    merchants: []
  }
}

export const clearMerchant = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUCCESS,
    merchant: {
      fein: '',
      legal_name: '',
      dba: '',
      city: '',
      state: '',
      business_phone: '',
      business_startdate: '',
      industry: '',
      category: '',
      note: '',
      submission_source: '',
      broker_name: ''
    }
  }
}

export const fetchMerchantsSuccess = (merchants, pagination) => {
  return {
    type: actionTypes.FETCH_MERCHANTS_SUCCESS,
    merchants: merchants,
    pagination: pagination
  }
}

export const fetchMerchantsFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANTS_FAIL,
    error: error
  }
}

export const fetchMerchantsStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANTS_START
  }
}

export const fetchMerchants = (page, per_page, search) => { 
  return dispatch => {
    dispatch(fetchMerchantsStart())
    let url = `/merchants?page=${page + 1}&per_page=${per_page}&search=${search}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchants = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedMerchants.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(clearNotes())
        dispatch(clearMerchant())
        dispatch(fetchMerchantsSuccess(fetchedMerchants, pagination))
      }
      else {
        dispatch(fetchMerchantsFail('Unable to load merchants.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchants.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantsFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const fetchMerchantSuccess = (merchant) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SUCCESS,
    merchant: merchant
  }
}

export const fetchMerchantFail = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_FAIL,
    error: error
  }
}

export const fetchMerchantStart = () => {
  return {
    type: actionTypes.FETCH_MERCHANT_START
  }
}

export const fetchMerchant = (token, id) => {
  return dispatch => {
    dispatch(fetchMerchantStart())
    let url = `/merchants/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchant = {...response.data.data}
        dispatch(fetchMerchantSuccess(fetchedMerchant))
      } else {
        dispatch(fetchMerchantFail('An error occurred while attempting to fetch merchant.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve merchant.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchMerchantFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const addMerchantStart = () => {
  return {
    type: actionTypes.ADD_MERCHANT_START
  }
}

export const addMerchantSuccess = (id, merchantData) => {
  return {
    type: actionTypes.ADD_MERCHANT_SUCCESS,
    id: id,
    merchantData: merchantData
  }
}

export const addMerchantFail = (error) => {
  return {
    type: actionTypes.ADD_MERCHANT_FAIL,
    error: error
  }
}

export const addMerchant = (merchantData, token) => {
  const modifiedMerchantData = {
    merchant: {
      fein: merchantData.merchant.fein,
      legal_name: merchantData.merchant.legal_name,
      dba: merchantData.merchant.dba,
      city: merchantData.merchant.city,
      state: merchantData.merchant.state,
      business_phone: merchantData.merchant.business_phone,
      business_startdate: merchantData.merchant.business_startdate,
      industry: merchantData.merchant.industry
    },
    note: {
      category: merchantData.merchant.category,
      note: merchantData.merchant.note,
      submission_source: merchantData.merchant.submission_source,
      broker_name: merchantData.merchant.broker_name
    }
  }

  if (merchantData.merchant.proxied_creator !== null){
    modifiedMerchantData.merchant.proxied_creator = merchantData.merchant.proxied_creator
  }

  return dispatch => {
    dispatch(addMerchantStart())
    let url = '/merchants'
    let config = authHeaders()
    axios.post(url, modifiedMerchantData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addMerchantSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Merchant has been added to DataMerch.',
          severity: 'success'
        }))
      } else {
        dispatch(addMerchantFail('An error occurred while attempting to create merchant.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to create merchant.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addMerchantFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const updateMerchantStart = () => {
  return {
    type: actionTypes.UPDATE_MERCHANT_START
  }
}

export const updateMerchantSuccess = (id, merchantData) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_SUCCESS,
    id: id,
    merchantData: merchantData
  }
}

export const updateMerchantFail = (error) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_FAIL,
    error: error
  }
}

export const updateMerchant = (merchantData, id, token) => {
  const modifiedMerchantData = {
    merchant: {
      fein: merchantData.merchant.fein,
      legal_name: merchantData.merchant.legal_name,
      dba: merchantData.merchant.dba,
      city: merchantData.merchant.city,
      state: merchantData.merchant.state,
      business_phone: merchantData.merchant.business_phone,
      business_startdate: merchantData.merchant.business_startdate,
      industry: merchantData.merchant.industry
    }
  }
  
  return dispatch => {
    dispatch(updateMerchantStart())
    let url = `/merchants/${id}`
    let config = authHeaders()
    axios.put(url, modifiedMerchantData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateMerchantSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Merchant has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(updateMerchantFail('An error occurred while attempting to update merchant.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update merchant.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateMerchantFail(error.response.data.errors))
    })
  }
}

export const archiveMerchantStart = () => {
  return {
    type: actionTypes.ARCHIVE_MERCHANT_START
  }
}

export const archiveMerchantSuccess = (id, merchantData) => {
  return {
    type: actionTypes.ARCHIVE_MERCHANT_SUCCESS,
    id: id,
    merchantData: merchantData
  }
}

export const archiveMerchantFail = (error) => {
  return {
    type: actionTypes.ARCHIVE_MERCHANT_FAIL,
    error: error
  }
}

export const archiveMerchant = (token, merchantData) => {
  const modifiedMerchantData = {
    delete_merchant: {
      reason: merchantData.reason
    }
  }
  
  return dispatch => {
    dispatch(archiveMerchantStart())
    let url = `merchants/${merchantData.merchant_id}/archive`
    let config = authHeaders()
    axios.put(url, modifiedMerchantData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(archiveMerchantSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Merchant has been archived.',
          severity: 'success'
        }))
      } else {
        dispatch(updateMerchantFail('An error occurred while attempting to archive merchant.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to archive merchant.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(archiveMerchantFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}

export const searchMerchantsSuccess = (merchants) => {
  return {
    type: actionTypes.SEARCH_MERCHANTS_SUCCESS,
    merchants: merchants
  }
}

export const searchMerchantsFail = (error) => {
  return {
    type: actionTypes.SEARCH_MERCHANTS_FAIL,
    error: error
  }
}

export const searchMerchantsStart = () => {
  return {
    type: actionTypes.SEARCH_MERCHANTS_START
  }
}

export const searchMerchantsReset = () => {
  return {
    type: actionTypes.SEARCH_MERCHANTS_RESET
  }
}

export const searchMerchantsResults = () => {
  return {
    type: actionTypes.SEARCH_MERCHANTS_RESULTS
  }
}

export const searchMerchants = (token, search, user) => {
  return dispatch => {
    // if  (user.account_contribution_locked){
    //   dispatch(searchMerchantsReset())
    //   dispatch(addToast({
    //     message: "You are not allowed to search because your merchant data contribution percentage is too low.",
    //     severity: 'error',
    //     duration: null
    //   }))
    // } else {
      dispatch(searchMerchantsReset())
      dispatch(searchMerchantsStart())
      let url = `/search/merchants?search=${search}`
      let config = authHeaders()
      axios_default.get(url, config)
      .then((response) => {
        if (response){
          const searchedMerchants = []
          for(let key in response.data.data){
            searchedMerchants.push({
              ...response.data.data[key],
              id: key
            })
          }
          dispatch(searchMerchantsSuccess(searchedMerchants))
          dispatch({ type: actionTypes.SEARCH_MERCHANTS_RESULTS, merchants: searchedMerchants })
        } else {
          console.log(response)
          // dispatch(openMerchantNotFoundDialog())
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 404){
          dispatch(openMerchantNotFoundDialog())
        } else {
          dispatch(addToast({
            message: error.response.data.errors,
            severity: 'error',
            duration: null
          }))
        }
        dispatch(searchMerchantsFail(error.response.data.errors))
      })
    // }
  }
}

export const openMerchantNotFoundDialog = () => {
  return {
    type: actionTypes.OPEN_MERCHANT_NOT_FOUND_DIALOG
  }
}

export const closeMerchantNotFoundDialog = () => {
  return {
    type: actionTypes.CLOSE_MERCHANT_NOT_FOUND_DIALOG
  }
}

export const openMerchantExistsDialog = (id) => {
  return {
    type: actionTypes.OPEN_MERCHANT_EXISTS_DIALOG,
    id: id
  }
}

export const closeMerchantExistsDialog = () => {
  return {
    type: actionTypes.CLOSE_MERCHANT_EXISTS_DIALOG
  }
}

export const initializeAddMerchant = () => {
  return {
    type: actionTypes.INITIALIZE_ADD_MERCHANT
  }
}

export const initializeAddNoteToMerchant = (id) => {
  return {
    type: actionTypes.INITIALIZE_ADD_NOTE_TO_MERCHANT,
    id: id
  }
}

export const openMerchantDeleteModal = (id) => {
  return {
    type: actionTypes.OPEN_MERCHANT_DELETE_MODAL,
    id: id
  }
}

export const closeMerchantDeleteModal = () => {
  return {
    type: actionTypes.CLOSE_MERCHANT_DELETE_MODAL
  }
}

export const toggleMerchantSubscriptionSuccess = (merchant) => {
  return {
    type: actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_SUCCESS,
    merchant: merchant
  }
}

export const toggleMerchantSubscriptionFail = (error) => {
  return {
    type: actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_FAIL,
    error: error
  }
}

export const toggleMerchantSubscriptionStart = () => {
  return {
    type: actionTypes.TOGGLE_MERCHANT_SUBSCRIPTION_START
  }
}

export const toggleMerchantSubscription = (id) => {
  return dispatch => {
    dispatch(toggleMerchantSubscriptionStart())
    let url = `/merchants/${id}/toggle_subscription`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedMerchant = {...response.data.data}
        dispatch(toggleMerchantSubscriptionSuccess(fetchedMerchant))
      } else {
        dispatch(toggleMerchantSubscriptionFail('An error occurred while attempting to toggle merchant subscription.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to toggle merchant subscription.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(toggleMerchantSubscriptionFail(error.response.data.errors))
      dispatch(addToast({
        message: error.response.data.errors,
        severity: 'error',
        duration: null
      }))
    })
  }
}
