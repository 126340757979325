import React, {useState, useEffect} from 'react';

import withErrorHandler from '../../../hocs/withErrorHandler'
import { makeStyles, useTheme } from '@material-ui/styles';
import axios from '../../../httpClients/axios-datamerch'

import {
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  IconButton,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MemberSelect from '../../Account/MembersSelect'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const MerchantInfo = props => {
  const classes = useStyles();
  const { 
    onChange,
    fein,
    legal_name,
    dba,
    city,
    state,
    business_phone,
    business_startdate,
    industry,
    proxied_creator,
    onFEINBlur,
    accounts,
    loading,
    is_dm_admin,
    is_admin,
    account_merchant_subscription_enabled,
    merchant_add,
    has_merchant_subscription,
    onMerchantSubscriptionToggle
  } = props;

  useEffect(() => {
  },[accounts])
  
  const [show_additional_info, setShowAdditionalInfo] = useState(false)

  const states = [
    {
      value: '',
      label: ''
    },
    {
      value: 'AL',
      label: 'Alabama'
    },
    {
      value: 'AK',
      label: 'Alaska'
    },
    {
      value: 'AZ',
      label: 'Arizona'
    },
    {
      value: 'AR',
      label: 'Arkansas'
    },
    {
      value: 'CA',
      label: 'California'
    },
    {
      value: 'CANADA',
      label: 'Canada'
    },
    {
      value: 'CO',
      label: 'Colorado'
    },
    {
      value: 'CT',
      label: 'Connecticut'
    },
    {
      value: 'DE',
      label: 'Delaware'
    },
    {
      value: 'DC',
      label: 'District of Columbia'
    },
    {
      value: 'FL',
      label: 'Florida'
    },
    {
      value: 'GA',
      label: 'Georgia'
    },
    {
      value: 'HI',
      label: 'Hawaii'
    },
    {
      value: 'ID',
      label: 'Idaho'
    },
    {
      value: 'IL',
      label: 'Illinois'
    },
    {
      value: 'IN',
      label: 'Indiana'
    },
    {
      value: 'IA',
      label: 'Iowa'
    },
    {
      value: 'KS',
      label: 'Kansas'
    },
    {
      value: 'KY',
      label: 'Kentucky'
    },
    {
      value: 'LA',
      label: 'Louisiana'
    },
    {
      value: 'ME',
      label: 'Maine'
    },
    {
      value: 'MD',
      label: 'Maryland'
    },
    {
      value: 'MA',
      label: 'Massachusetts'
    },
    {
      value: 'MI',
      label: 'Michigan'
    },
    {
      value: 'MN',
      label: 'Minnesota'
    },
    {
      value: 'MS',
      label: 'Mississippi'
    },
    {
      value: 'MO',
      label: 'Missouri'
    },
    {
      value: 'MT',
      label: 'Montana'
    },
    {
      value: 'NE',
      label: 'Nebraska'
    },
    {
      value: 'NV',
      label: 'Nevada'
    },
    {
      value: 'NH',
      label: 'New Hampshire'
    },
    {
      value: 'NJ',
      label: 'New Jersey'
    },
    {
      value: 'NM',
      label: 'New Mexico'
    },
    {
      value: 'NY',
      label: 'New York'
    },
    {
      value: 'NC',
      label: 'North Carolina'
    },
    {
      value: 'ND',
      label: 'North Dakota'
    },
    {
      value: 'OH',
      label: 'Ohio'
    },
    {
      value: 'OK',
      label: 'Oklahoma'
    },
    {
      value: 'OR',
      label: 'Oregon'
    },
    {
      value: 'PA',
      label: 'Pennsylvania'
    },
    {
      value: 'PR',
      label: 'Puerto Rico'
    },
    {
      value: 'RI',
      label: 'Rhode Island'
    },
    {
      value: 'SC',
      label: 'South Carolina'
    },
    {
      value: 'SD',
      label: 'South Dakota'
    },
    {
      value: 'TN',
      label: 'Tennessee'
    },
    {
      value: 'TX',
      label: 'Texas'
    },
    {
      value: 'UT',
      label: 'Utah'
    },
    {
      value: 'VT',
      label: 'Vermont'
    },
    {
      value: 'VA',
      label: 'Virginia'
    },
    {
      value: 'WA',
      label: 'Washington'
    },
    {
      value: 'WV',
      label: 'West Virginia'
    },
    {
      value: 'WI',
      label: 'Wisconsin'
    },
    {
      value: 'WY',
      label: 'Wyoming'
    },
    {
      value: 'OTHER',
      label: 'Other'
    }
  ];

  const toggleAdditionalInformation = () => {
    setShowAdditionalInfo(!show_additional_info)
  }

  let membersSelect = null

  if (is_dm_admin && merchant_add){
    membersSelect = (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
    if(!loading){
      membersSelect = (
        <MemberSelect
          accounts={accounts || []}
          proxied_creator={proxied_creator}
          label="Add on Behalf of"
          onChange={onChange}
        />
      )
    }
  }

  let subscriptionToggle = null

  if ((is_dm_admin || is_admin) && account_merchant_subscription_enabled){
    subscriptionToggle = (
      <Grid
        item
        md={12}
        xs={12}
      >
        <FormControlLabel
          control={<Switch 
            checked={has_merchant_subscription}
            onChange={onMerchantSubscriptionToggle}
            name="merchant_subscription_enabled"
            color="primary"
          />}
          label="Subscription Enabled"
        />
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <CardHeader
        title="Merchant Information"
        action={subscriptionToggle}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            {membersSelect}
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              helperText="Federal employer identification number or business number"
              label="EIN or BN"
              margin="dense"
              name="fein"
              onChange={(event) => onChange(event, 'fein')}
              required
              value={fein || ''}
              variant="outlined"
              inputProps={{
                'maxLength': '10',
                'minLength': '10',
                'pattern':'.{10,10}'
              }}
              onBlur={(event) => onFEINBlur(event)}
              autoFocus
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              helperText="Legal name of merchant"
              label="Legal Name"
              margin="dense"
              name="legal_name"
              onChange={(event) => onChange(event, 'legal_name')}
              required
              value={legal_name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="City"
              margin="dense"
              name="city"
              onChange={(event) => onChange(event, 'city')}
              required
              value={city || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="State"
              margin="dense"
              name="state"
              onChange={(event) => onChange(event, 'state')}
              required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={state || ''}
              variant="outlined"
            >
              {states.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardHeader
        title={
          <React.Fragment>
            Additional Information
            <IconButton aria-label="settings" onClick={toggleAdditionalInformation}>
              { show_additional_info ?
                <KeyboardArrowUpIcon />
                : 
                <KeyboardArrowDownIcon />
              }
            </IconButton>
          </React.Fragment>
        }
      />
      {show_additional_info ? 
        <React.Fragment>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Doing Business As"
                    margin="dense"
                    name="dba"
                    onChange={(event) => onChange(event, 'dba')}
                    value={dba || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Business Phone"
                    margin="dense"
                    name="business_phone"
                    onChange={(event) => onChange(event, 'business_phone')}
                    value={business_phone || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Business Start Date"
                    margin="dense"
                    name="business_startdate"
                    onChange={(event) => onChange(event, 'business_startdate')}
                    value={business_startdate || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Industry"
                    margin="dense"
                    name="industry"
                    onChange={(event) => onChange(event, 'industry')}
                    value={industry || ''}
                    variant="outlined"
                  />
                </Grid>
            </Grid>
          </CardContent>
        </React.Fragment>
      : null
      }
    </React.Fragment>
  );
};

export default withErrorHandler(MerchantInfo, axios)