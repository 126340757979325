import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import authReducer from './store/reducers/auth'
import merchantReducer from './store/reducers/merchant'
import deletedMerchantReducer from './store/reducers/deletedMerchant'
import accountReducer from './store/reducers/account'
import userReducer from './store/reducers/user'
import noteReducer from './store/reducers/note'
import deletedNoteReducer from './store/reducers/deletedNote'
import toastsReducer from './store/reducers/toasts'
import appSettingsReducer from './store/reducers/appSettings'
import stripeReducer from './store/reducers/stripe'
import disputeReducer from './store/reducers/dispute'
import merchantSubscriptionReducer from './store/reducers/merchantSubscription'
import merchantSubscriptionImportReducer from './store/reducers/merchantSubscriptionImport'
import merchantSubscriptionWebhookReducer from './store/reducers/merchantSubscriptionWebhook'

const reducer = combineReducers({
  auth: authReducer,
  merchant: merchantReducer,
  deletedMerchant: deletedMerchantReducer,
  account: accountReducer,
  user: userReducer,
  note: noteReducer,
  deletedNote: deletedNoteReducer,
  toasts: toastsReducer,
  appSettings: appSettingsReducer,
  stripe: stripeReducer,
  dispute: disputeReducer,
  merchantSubscription: merchantSubscriptionReducer,
  merchantSubscriptionImport: merchantSubscriptionImportReducer,
  merchantSubscriptionWebhook: merchantSubscriptionWebhookReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(sagaMiddleware, thunk))
)
