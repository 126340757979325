import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { asPercent } from '../../../helpers';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CornerRibbon from '../../../components/UI/CornerRibbon';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  planLevel: {
    fontSize: '34px',
    paddingTop: 10,
    paddingBottom: 20
  },
  planLevelMembership: {
    fontSize: '24px',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    fontWeight: 'normal'
  },
  planLevelPrice: {
    fontSize: '36px',
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 20,
    marginBottom: 20,
    marginLeft: 10
  },
  planLevelPricePer: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingTop: 5
  },
  featureFalse: {
    color: theme.palette.error.main
  },
  featureNeutral: {
    color: theme.palette.warning.main
  },
  featureTrue: {
    color: theme.palette.success.main
  },
  planPosition: {
    position: 'relative'
  }
}));

const MembershipPlans = props => {
  const { 
    current_plan,
    onUpgradeMembershipClick,
    onDowngradeMembershipClick
  } = props;
  
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  // useEffect(() => {
  //   const errors = validate(formState.values, schema);

  //   setFormState(formState => ({
  //     ...formState,
  //     isValid: errors ? false : true,
  //     errors: errors || {}
  //   }));
  // }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={3}>
          { current_plan === "BASIC" ?
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/rPxwRSO_aK8?si=iuHIAKUGT88Wc00D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={3} className={classes.planPosition}>
                <Card>
                  <CardContent>
                    <Typography
                    variant="h3"
                    align="center"
                    >
                      <div className={classes.planLevel}>BASIC<span className={classes.planLevelMembership}>Membership</span></div>
                    </Typography>
                    <Divider />
                      <Button
                        className={classes.signInButton}
                        color="secondary"
                        size="large"
                        type="button"
                        variant="contained"
                        disabled
                      >
                        Current Plan
                      </Button>
                    <Typography
                      variant="h5"
                    >
                      <div className={classes.planLevelPrice}>$695<div className={classes.planLevelPricePer}>/month</div></div>
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Website Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Search for Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add New Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add Notes to Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="API Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                        </ListItemIcon>
                        <ListItemText primary="Up to 3000 Searches Per Day" />
                      </ListItem>
                      <ListItem>
                      <ListItemIcon>
                        <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                      </ListItemIcon>
                      <ListItemText primary="Keyword search returns up to 5 matches" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CancelIcon className={classes.featureFalse} />
                        </ListItemIcon>
                        <ListItemText primary="Merchant Subscriptions" />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={3} className={classes.planPosition}>
                <Card>
                <CardContent>
                    <CornerRibbon />
                    <Typography
                    variant="h3"
                    align="center"
                    >
                      <div className={classes.planLevel}><b>PRO</b><span className={classes.planLevelMembership}>Membership</span></div>
                    </Typography>
                    <Divider />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={onUpgradeMembershipClick}
                    >
                      Upgrade to PRO
                    </Button>
                    <Typography
                      variant="h5"
                    >
                      <div className={classes.planLevelPrice}>$945<div className={classes.planLevelPricePer}>/month</div></div>
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Website Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Search for Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add New Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add Notes to Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="API Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="UNLIMITED Searches Per Day" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Keyword search returns up to 25 matches" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Merchant Subscriptions"
                          secondary="Be notified automatically when updates occur"
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          :
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/rPxwRSO_aK8?si=iuHIAKUGT88Wc00D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={3} className={classes.planPosition}>
                <Card>
                <CardContent>
                    <CornerRibbon />
                    <Typography
                    variant="h3"
                    align="center"
                    >
                      <div className={classes.planLevel}><b>PRO</b><span className={classes.planLevelMembership}>Membership</span></div>
                    </Typography>
                    <Divider />
                    <Button
                      className={classes.signInButton}
                      color="secondary"
                      size="large"
                      type="button"
                      variant="contained"
                      disabled
                    >
                      Current Plan
                    </Button>
                    <Typography
                      variant="h5"
                    >
                      <div className={classes.planLevelPrice}>$945<div className={classes.planLevelPricePer}>/month</div></div>
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Website Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Search for Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add New Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add Notes to Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="API Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="UNLIMITED Searches Per Day" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Keyword search returns up to 25 matches" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Merchant Subscriptions"
                          secondary="Be notified automatically when updates occur"
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={3} className={classes.planPosition}>
                <Card>
                  <CardContent>
                    <Typography
                    variant="h3"
                    align="center"
                    >
                      <div className={classes.planLevel}>BASIC<span className={classes.planLevelMembership}>Membership</span></div>
                    </Typography>
                    <Divider />
                    <Button
                      className={classes.signInButton}
                      color="secondary"
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={onDowngradeMembershipClick}
                    >
                      Downgrade to BASIC
                    </Button>
                    <Typography
                      variant="h5"
                    >
                      <div className={classes.planLevelPrice}>$695<div className={classes.planLevelPricePer}>/month</div></div>
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Website Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Search for Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add New Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="Add Notes to Merchants" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon className={classes.featureTrue} />
                        </ListItemIcon>
                        <ListItemText primary="API Access" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                        </ListItemIcon>
                        <ListItemText primary="Up to 3000 Searches Per Day" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <IndeterminateCheckBoxIcon className={classes.featureNeutral} />
                        </ListItemIcon>
                        <ListItemText primary="Keyword search returns up to 5 matches" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CancelIcon className={classes.featureFalse} />
                        </ListItemIcon>
                        <ListItemText primary="Merchant Subscriptions" />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </div>
    </div>
  )
}

export default MembershipPlans;
