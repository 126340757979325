import React, { useState } from 'react';
import {connect} from 'react-redux'
import moment from 'moment';

import * as actions from '../../../store/actions'

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  Chip,
  Typography
} from '@material-ui/core';

import { NoteDialog } from '../../Note';
import { NoteDisputeDialog } from '../../Note';
import CategoryChip from '../../UI/CategoryChip'
import { CLOSE_MERCHANT_SUBSCRIPTION_DIALOG } from 'store/actions/actionTypes';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  note_date: {
    fontWeight: 'bold',
    fontSize: '1.25em'
  },
  note_creator: {
    fontWeight: 'bold',
    fontSize: '1.25em'
  },
  note_copy: {
    paddingTop: '10px'
  },
  tableRowHighlighted: {
    backgroundColor: 'yellow'
  }
}));

const NotesTable = props => {
  const { 
    notes,
    onArchiveClick,
    merchant_id,
    onSubmitNoteClick,
    onCancelNoteClick,
    onSubmitNoteDisputeClick,
    onCancelNoteDisputeClick,
    modalOpen,
    disputeModalOpen,
    onOpenNoteModal,
    current_user,
    accounts,
    is_dm_admin,
    loading_accounts,
    onOpenNoteDisputeModal,
    highlight,
    plan_level
  } = props;

  const classes = useStyles();

  const [note, setNote] = useState({
    id: null,
    merchant_id: merchant_id,
    category: '',
    note: '',
    submission_source: '',
    broker_name: ''
  })

  const [noteMode, setNoteMode] = useState('add')
  
  const handleAddNoteClick = () => {
    setNote({
      id: null,
      merchant_id: merchant_id,
      category: '',
      note: '',
      submission_source: '',
      broker_name: ''
    })
    setNoteMode('add')
    onOpenNoteModal()
  }

  const handleEditNoteClick = (event, note) => {
    setNote({
      id: note.resource_id,
      merchant_id: merchant_id,
      category: note.category,
      note: note.note,
      submission_source: note.submission_source,
      broker_name: note.broker_name
    })
    setNoteMode('edit')
    onOpenNoteModal()
  }

  const handleDeleteNoteClick = (event, note) => {
    setNote({
      id: note.resource_id,
      merchant_id: merchant_id,
      category: note.category,
      note: note.note,
      submission_source: note.submission_source,
      broker_name: note.broker_name
    })
    setNoteMode('dispute')
    onOpenNoteDisputeModal()
  }

  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={12} align={'right'}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={handleAddNoteClick}
                    >
                      Add Note
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notes.map(note => (
                  <TableRow
                    className={highlight && highlight === note.resource_id ? classes.tableRowHighlighted : classes.tableRow}
                    hover
                    key={note.id}
                  >
                    <TableCell colSpan={2}>
                      <CategoryChip category={note.category} />
                    </TableCell>
                    <TableCell colSpan={8}>
                      Added on &nbsp;&nbsp;&nbsp;
                      <span
                        className={classes.note_date}
                      >
                        {moment(note.created_at).format('MM/DD/YYYY')}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {is_dm_admin || (note.added_by_account_id == current_user.account_id && current_user.is_admin) ?
                        <span
                          className={classes.note_creator}
                        >
                          {note.added_by} - {note.added_by_user}
                        </span>
                        : <span
                            className={classes.note_creator}
                          >
                            {note.added_by}
                          </span>
                      }
                      { (note.submission_source === 'broker' && note.broker_name && (plan_level === 'PRO' || plan_level !== 'PRO')) ?
                        <p className={classes.note_copy}>
                          <Chip
                            size="small"
                            label={'File Submitted By:  '+ note.broker_name}
                            variant="outlined"
                          />
                        </p>
                      :
                      (note.submission_source === 'in-house' && (plan_level === 'PRO' || plan_level !== 'PRO')) ?
                        <p className={classes.note_copy}>
                          <Chip
                            size="small"
                            label={'File Submitted By:  in-house/retail'}
                            variant="outlined"
                          />
                        </p>
                      :
                        null
                      }
                      <p className={classes.note_copy}>{note.note}</p>                 
                    </TableCell>
                    <TableCell colSpan={2} align='right'>
                      { (note.created_by_id === current_user.id || current_user.is_dm_admin) ? 
                        <React.Fragment>
                          <IconButton aria-label="edit" onClick={event => handleEditNoteClick(event, note)}>
                            <EditIcon />
                          </IconButton>
                        </React.Fragment>
                      : null }
                      { (current_user.is_dm_admin) ? 
                        <React.Fragment>
                          <IconButton aria-label="delete" onClick={event => onArchiveClick(event, note.resource_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </React.Fragment>
                      : null }
                      { (!current_user.is_dm_admin && (current_user.account_id === note.added_by_account_id)) ? 
                        <React.Fragment>
                          <IconButton aria-label="delete" onClick={event => handleDeleteNoteClick(event, note)}>
                            <DeleteIcon />
                          </IconButton>
                        </React.Fragment>
                      : null }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <NoteDialog
        open={modalOpen}
        mode={noteMode}
        is_dm_admin={is_dm_admin}
        accounts={accounts}
        loading={loading_accounts}
        onSubmitClick={onSubmitNoteClick} 
        onCancelClick={onCancelNoteClick}
        merchantNote={note}
        plan_level={plan_level}
      />
      <NoteDisputeDialog
        open={disputeModalOpen}
        mode={noteMode}
        is_dm_admin={is_dm_admin}
        loading={loading_accounts}
        onSubmitClick={onSubmitNoteDisputeClick} 
        onCancelClick={onCancelNoteDisputeClick}
        merchantNote={note}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    modalOpen: state.note.modalOpen,
    disputeModalOpen: state.note.disputeModalOpen,
    current_user: state.auth.user,
    is_dm_admin: state.auth.user.is_dm_admin,
    plan_level: state.auth.user.plan_level
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenNoteModal: () => dispatch(actions.openNoteModal()),
    onOpenNoteDisputeModal: () => dispatch(actions.openNoteDisputeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesTable)
