import React from 'react';
import {connect} from 'react-redux'
import * as actions from '../../../store/actions'

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Divider } from '@material-ui/core';

const MerchantNotFoundDialog = props => {
  const { 
    openDialog,
    onCloseDialog,
    onChooseAdd
  } = props;
  
  const handleDialogClose = () => {
    onCloseDialog()
  };

  const handleChooseAdd = () => {
    onChooseAdd()
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"No Merchants Found"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          No merchants were found matching your search.  Would you llike to add a merchant into DataMerch?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          No
        </Button>
        <Button
          onClick={handleChooseAdd}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes, I want to add a merchant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    openDialog: state.merchant.merchant_not_found_dialog_open
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseDialog: () => dispatch(actions.closeMerchantNotFoundDialog()),
    onChooseAdd: () => dispatch(actions.initializeAddMerchant())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantNotFoundDialog)
